.product-container {
  border-radius: 5px;
  border: 1px solid #b8c5d3;
  height: 75vh;
  width: 95%;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.product-top-tabs {
  overflow-x: hidden;
}

.product-top-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b8c5d3;
  margin: 5px 10px;
}

.product-top-menu-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  text-align: center;
}

/* .product-top-menu-circle {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #ff4b30;
  color: #ffffff;
  text-align: center;
} */

.product-top-menu-button {
  background: #7a90a6;
  border: 1px solid white;
  width: 35px;
  border-radius: 10%;
  color: white;
  font-size: 14px;
  font-weight: bolder;
  margin: 0 0 0 5px;
}

.product-top-menu-right h5 {
  margin-top: 2px;
  color: #b8c5d3;
}

.product-search-container {
  margin: 25px 30px;
}

.product-airtable-container {
  width: 100%;
  background-color: white;
  margin: 0 10px;
}

.product-airtable-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0 0 0;
  width: 100%;
  align-items: center;
  padding: 0;
  height: 10%;
  background-color: white;
}

.product-airtable-count {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.product-airtable-search-button {
  border-radius: 0 40px 40px 0;
  height: 30px;
  margin: 0;
}

.product-airtable-search-container {
  width: auto;
  margin-top: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.product-airtable-search {
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
}

.product-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 5px 20px;
  overflow-y: hidden;
}

.product-card-container-default-text {
  padding: 0 15px;
}

.product-item-count {
  display: flex;
  justify-content: center;
  margin-bottom: -18px;
}

.backgroundmodal-upload-button {
  width: 100%;
  margin-bottom: 25px;
  padding: 10px;
  color: #90999f;
  border: 1px solid #e1e6eb;
  box-sizing: border-box;
  border-radius: 3px;
}

.backgroundmodal-preview {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.airtable-productDetail-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.airtable-productDetail-top {
  background-color: 'transparent';
  display: flex;
  justify-content: center;
  box-shadow: 'none';
  border: '0';
  padding: 20px 20px;
}

.airtable-productDetail-button-container {
  position: fixed;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.airtable-productDetail-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 50px 0 0;
}

.airtable-productDetail-right {
  width: 40%;
}

.productDetail-container {
  width: auto;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
  max-height: 90vh;
  overflow-y: scroll;
  padding: 25px 25px;
  margin: 0;
}

.productDetail {
  background-color: 'transparent';
  display: flex;
  justify-content: center;
  box-shadow: 'none';
  border: '0';
  padding: 10px 0px;
}

.productDetail-button-container {
  position: fixed;
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
}

.productDetail-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 50px 0 0;
}

.productDetail-left .carousel .slide {
  background: none;
}

.productDetail-right {
  width: 40%;
}

.carousel {
  background-color: white !important;
}

.carousel .slide {
  background-color: none !important;
}

/* Document Container*/
.docs-container {
  width: 100%;
  display: flex;
  justify-content: left;
  margin: 20px 20px;
}

.docs-button {
  padding: 10px 10px;
  border: none;
  font-size: 13px;
  margin: 5px 5px;
}
