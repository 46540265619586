.canvas {
  width: 895px;
  height: 640px;
  display: block;
  border: 0.5px solid #aaa7a7;
}

.canvas .item {
  position: absolute;
  cursor: pointer;
}

.item .unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.canvas .item .resizer {
  width: 24px;
  height: 24px;
  margin-bottom: -24px;
  transform: rotate(45deg);
  cursor: se-resize;
}

.canvas .item .resizer-hidden {
  display: none;
}

.draggable-content {
  display: flex;
}

.fh-moodboard--canvas--item-options {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  opacity: 0;
}

.fh-moodboard--canvas--item-options--hidden {
  display: none;
}

.draggable-content:hover .fh-moodboard--canvas--item-options {
  opacity: 1;
  transition: 0.5s ease;
}

.canvas-topBar {
  display: flex;
  justify-content: space-between;
  background-color: rgb(170, 167, 167);
  padding: 5px 5px;
}

.canvas-topBar-button {
  border-radius: 5px;
  padding: 5px 8px;
  margin-left: 5px;
  font-weight: bold;
}
.canvas-topBar-button-left-container {
  padding: 0;
  height: 100%;
}

.canvas-topBar-button-right {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.canvas-topBar-button-right > button {
  border-radius: 5px;
  margin-right: 5px;
}
/* .fh-moodboard--canvas--item__remove {
  flex-grow: 1;
  width: 24px;
  height: 24px;
  margin-top: -24px;
} */

.fh-moodboard--canvas--item__handle {
  align-self: flex-end;
  background-color: red;
}

/* .fh-moodboard--canvas--remove-bg {
  flex-grow: 2;
  width: 24px;
  height: 24px;
  margin-top: -24px;
} */

.canvas-textDrop {
  width: auto;
  margin: 0;
  padding: 0;
}
.canvas-textDrop > h1 {
  margin: 0;
  padding: 0;
}

.canvas-textDrop-container {
  position: absolute;
}

.canvas-textDrop-text {
  display: flex;
  cursor: pointer;
}

.canvas-text-edit-button {
  display: none;
}

.apply-font {
  padding: 0;
  margin: 0;
}

.apply-font:hover + .canvas-text-edit-button,
.canvas-text-edit-button:hover {
  display: flex;
  align-items: flex-end;
}

.canvas-textDrop-input {
  border: 0.8px solid black;
  border-radius: 5px;
  padding: 5px 5px;
  margin: 0;
}

.canvas-textDrop-button {
  border-radius: 5px;
  padding: 5px 5px;
  margin: 0;
}

.overlay-image .hover {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.overlay-image:hover .hover {
  opacity: 1;
}

.overlay-image .arrow-button {
  color: black;
  font-size: 20px;
  line-height: 1.5em;
  text-shadow: 2px 2px 2px white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* .overlay-image .image {
  display: block;
  width: 100%;
  height: auto;
} */
