.gridView-top-banner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #748aa1;
  color: white;
  z-index: 1000;
}

.gridView-container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  min-height: 340px;
  height: 100vw;
  width: 100vw;
  background-color: white;
}

.gridview-canvas {
  grid-column: 2 / 2;
  border: 1px solid #b8c5d3;
  width: 898px;
  height: 608px;
  display: block;
}
.gridview-canvas .item {
  position: absolute;
  cursor: pointer;
}

.gridview-canvas-container {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridview-canvas-container > h1 {
  text-shadow: black;
}
