.props-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  font-size: 8px;
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
  color: #748aa1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  width: 120px;
}

.props-card > div {
  padding: 5px;
}

.props-card p {
  margin: 0;
  padding: 0;
}

.props-card img {
  height: 50px;
}

.props-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.props-card:hover + .props-card-delete,
.props-card-delete:hover {
  font-size: large;
  transition: 0.2s;
}
