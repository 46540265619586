.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5vh;
  background-color: #252b48;
  color: white;
  text-align: left;
  padding: 0 10px;
  font-size: 12px;
}

.footer-button {
  font-size: 14px;
  color: white;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px 10px;
  margin-right: 30px;
}
