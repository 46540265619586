.moodboard-container {
  display: flex;
  min-height: 340px;
  width: 100vw;
  overflow-y: scroll;
  height: 80vh;
}

.moodboard-container-collapsed {
  position: relative;
  width: 5%;
  height: 80vh;
  border-right: 0.5px solid #b8c5d3;
  color: #878b98;
  /* padding: 0px 0 10px 15px; */
  overflow-y: scroll;
}

.moodboard-container-collapsed > div {
  /* position: sticky;
  top: 0; */
  padding-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.moodboard-container-left {
  position: relative;
  width: 10%;
  min-width: 8%;
  height: 80vh;
  border-right: 0.5px solid #b8c5d3;
  color: #878b98;
  /* padding: 0px 0 10px 15px; */
  overflow-y: scroll;
}

.moodboard-container-left > div {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: flex-end;
  width: 100%;
  margin-left: -2px;
}

.moodboard-container-right {
  display: flex;
  position: relative;
  flex-direction: column;
  /* min-width: 85%; */
  flex-grow: 1;
  min-height: 100%;
  overflow-y: scroll;

  /* padding: 20px 25px; */
  /* flex-direction: row; */
}

.moodboard-container-right h5 {
  margin: -20px 0 0 10px;
  cursor: pointer;
  font-size: 10px;
  color: #ff4b30;
}

.moodboard-container-right > div {
  display: flex;
  margin-top: 30px;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  justify-content: center;
}

.moodboard-container-room-select {
  cursor: pointer;
}
.moodboard-container-room-span {
  font-weight: bold;
  width: 100%;
  color: #878b98;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #748aa1;
  margin: 5px 5px;
  padding: 5px 0 5px 10px;
}
.moodboard-wrapper {
  border-radius: 5px;
  border: 1px solid #b8c5d3;
  min-height: 100%;
  width: 898px;
}

.moodboard-topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.8% 2%;
  height: 6%;
  border-radius: 5px 5px 0 0;
  background-color: #b8c5d3;
}

.moodboard-topBar-buttons {
  display: flex;
  justify-content: space-evenly;
}

.moodboard-canvas {
  width: 100%;
  min-height: 80vh;
  background-color: white;
  border-radius: 0 0 5px 5px;
  display: flex;
  flex-direction: column;
}

.addRoomTypeModal-dropDown {
  width: 30%;
  margin-right: 10px;
}
