.style-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  font-size: 12px;
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
  color: #748aa1;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 5px;
  cursor: pointer;
  width: 240px;
  height: 100%;
}

.style-card-imgModal {
  width: 230px;
}

.style-card > div {
  padding: 5px;
}

.style-card p {
  margin: 0;
  padding: 0;
}

.style-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.style-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 20px;
}
