.backgroundProps-container {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}

.backgroundProps-button-container {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.backgroundProps-button {
  width: 200px;
  height: 35px;
  margin-bottom: 15px;
  color: white;
  background-color: #7b90a6;
  border-radius: 5px;
  box-shadow: none;
  border: none;
}

.backgroundProps-item-count {
  display: flex;
  justify-content: center;
  margin: 5px;
}

.backgroundProps-card-container {
  margin-top: 7px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 5px 20px;
  position: relative;
}
