.product-card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  font-size: 9px;
  font-weight: bold;
  background-color: white;
  border-radius: 10px;
  color: #748aa1;
  display: flex;
  flex-direction: column;

  text-align: center;
  margin: 7px;
  cursor: pointer;
  width: 120px;
  height: 220px;
}
.product-card-top {
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.product-card-imageContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.product-card p {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-card img {
  width: 80px;
}

.product-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.product-container-dimension {
  background-color: #252b48;
  border-radius: 0 0 10px 10px;
  padding: 5px 0;
}

.product-container-dimension > button {
  border-radius: 5px;
}

.product-card-airtable-days-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
