.moodboard-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15vh;
  background-color: rgba(116, 138, 161, 0.5);
}

.moodboard-navbar-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 100%;
}

.moodboard-navbar-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 85%;
  height: 100%;
}

.moodboard-navbar-right .project-description {
  padding: 10px 50px 10px 0;
  display: flex;
  flex-direction: column;
  width: 40%;
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: right;
}

.moodboard-navbar-right .project-cost {
  border-left: 5px solid #ffffff;
  margin: 50px 0;
  padding: 0 50px;
  width: 25%;
  color: #060e23;
}
.moodboard-navbar-right .project-cost div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.moodboard-navbar-right .project-cost h5 {
  padding: 0;
  margin: 2px;
}

.moodboard-navbar-left h3 {
  margin: 0;
  color: #b8c5d3;
}

.moodboard-navbar-right h3 {
  color: #ffffff;
}

.project-title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
