.grid-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5vh;
  background-color: #252b48;
  color: white;
  text-align: left;
  padding: 0 10px;
  font-size: 12px;
}
