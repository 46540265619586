.landingPage-container {
  background-color: #f5f6fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 95vh;
  padding: 0 0 10vh 0;
}

.landingPage-form {
  width: 400px;
  text-align: center;
  box-shadow: none;
}

.landingPage-form h3 {
  margin: 0 0 70px 0;
  font-size: 24px;
  color: #b8c5d3;
}

.landingPage-form h1 {
  margin: 0 0 70px 0;
  font-size: 24px;
  color: white;
}

.landingPage-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.switchmodal-container {
  width: '600px';
  background-color: '#748aa1';
  box-shadow: 'none';
  border: '0';
  padding: 20px 20px;
}

.backgroundUploadModal-container {
  width: '600px';
  box-shadow: 'none';
  border: '0';
  padding: 20px 20px;
  border-radius: 5px;
}

.rivision-modal {
  width: '600px';
  background-color: white;
  box-shadow: 'none';
  border: '0';
  padding: 20px 20px;
  border-radius: 5px;
  max-height: 90vh;
  overflow-y: scroll;
}

.addRoomTypeModal-dropDown {
  width: 100%;
  font-size: 20px;
}

.rivision-landingPage-form {
  width: 400px;
  height: 80%;
  overflow-y: scroll;
  text-align: center;
  box-shadow: none;
}

.rivision-landingPage-form h3 {
  margin: 0 0 70px 0;
  font-size: 24px;
  color: black;
}

.rivision-landingPage-form h1 {
  margin: 0 0 70px 0;
  font-size: 24px;
  color: black;
}

.clientmodal-button {
  width: 20%;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #748aa1;
  border: none;
  color: white;
}

.clientmodal-container {
  width: '600px';
  border-radius: 5px;
  box-shadow: 'none';
  border: '0';
  padding: 20px 20px;
}

.clientmodal-form {
  width: 400px;
  text-align: center;
  box-shadow: none;
}

.clientmodal-form h1 {
  color: white;
  text-decoration: underline;
  margin-bottom: 40px;
}

.clientmodal-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.clientmodal-input {
  width: 80%;
  border-radius: 5px;
  margin-left: 5px;
  border: 1px solid black;
}
